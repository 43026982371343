@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  background-image: url(./images/background.png);
  background-size: 100vw;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  box-shadow:0 0 50px 50px black; }

@media (min-width: 768px) {
  body {
    background-size: 100vw 100vh;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
